@import "src/styles/colors";

.candidates-merge-page {
  padding-top: 208px;

  &:before {
    content: "";
    position: fixed;
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 40px;
    background-color: #FCFCFC;
  }

  .title-data {
    font-weight: 600;
  }

  .ant-table-cell {
    vertical-align: top;

    &:has(.header-cell) {
      background-color: $disabled_bg;
    }

    &.with-border:not(:has(.header-cell)) {
      border-left: 1px solid $border;
    }

    .disabled {
      color: $lightgray;
    }

    &.merged-info {
      padding-right: 32px;
    }

    .expandable-content {
      overflow: hidden;
      * {
        line-height: 16px;
      }

      &:has(.expand-button) {
        padding-right: 32px;
      }

      &:not(.opened) {
        max-height: 64px;
      }
    }

    .expand-button {
      position: absolute;
      right: 12px;
      bottom: 8px;
    }

    .ant-radio-group {
      display: block;

      .ant-radio-wrapper {
        display: flex;
        align-items: unset;

        .ant-radio {
          height: 16px;
          top: 0;
        }
      }
    }

    .ant-checkbox-wrapper .ant-checkbox {
      top: 3px;
    }

    .input-default-wrapper,
    .select-default {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 36px;
      margin: 0;
      padding: 0;

      input,
      .ant-select-selector {
        border-radius: 0;
      }
    }
  }

  .required {
    color: $accent_70;
  }

  .edit-button {
    position: absolute;
    top: 9px;
    right: 12px;
    cursor: pointer;

    path {
      fill: $placeholder;
    }

    &:hover path {
      fill: $accent_70;
    }
  }
}

#form-edit-offices,
#form-edit-languages {
  .form-list-double-select__item,
  .form-list-sources__item {
    flex-direction: column;

    &__first,
    &__second {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &__second {
      margin-top: 0;
      padding-top: 8px;
    }
  }
}

#form-edit-links {
  .form-list__item {
    flex-direction: column;

    &__first,
    &__second {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.popup-edit-working-experiences {
  max-width: 660px;
  width: 100% !important;

  .experience-list__item {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;

    &:nth-child(n+2) {
      border-radius: 0;
      border-top: 1px solid $border;
    }

    .ant-row:nth-child(n+2) > .ant-col {
      flex: 0 0 100%;
      max-width: 100%;

      > .ant-row {
        margin-left: -16px !important;
        margin-right: -16px !important;
        > .ant-col {
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
      }
    }
  }
}
