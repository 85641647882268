@import "../../styles/colors";

.candidate-duplicate-popup {
  .ant-modal-body {
    padding-bottom: 24px;
  }
  .duplicate-item {
    &__header {
      display: flex;
      justify-content: space-between;

      &__full-name {
        max-width: calc(100% - 160px);
        .typography {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .button-default {
        flex: 0 0 100%;
      }
    }

    &__content {
      margin: 0;
      padding: 0;
      a {
        display: block;
        width: fit-content;
      }

      li {
        margin-top: 8px;
        display: flex;
        align-items: center;
        width: max-content;
        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__buttons {
      margin-top: 16px;
      display: flex;
      gap: 8px;

      > a {
        flex: 1;

        button {
          width: 100%;
        }
      }
    }

    .duplicate {
      background-color: $accent_20;
    }
  }
}