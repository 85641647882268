@import "src/styles/breakpoints.scss";
@import "src/styles/colors.scss";

.timeline-item {
  margin-top: 32px;
  padding-left: 44px;
  position: relative;

  &__header {
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .icon {
      position: absolute;
      left: 0;
    }

    .title {
      color: $blackblue;
    }

    .actions {
      margin-left: 16px;
      display: flex;
      align-items: center;
      color: $darkgray;
      white-space: nowrap;

      .ats-content-menu {
        margin-left: 16px;
        position: relative;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__body {
    .info-table {
      margin-top: 8px;
      table-layout: fixed;
      width: 100%;
      overflow: hidden;

      &__name {
        padding: 0;
        width: 140px;
        color: $placeholder;
        vertical-align: top;
        font-size: 12px;
      }

      &__data {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        color: $blackblue;

        .previous-value {
          color: $placeholder;
        }

        .current-value {
          flex: 1;
        }

        .arrow {
          margin: auto 4px;
        }

        .deleted {
          text-decoration: line-through;
        }

        .rich-area-default__rich-content {
          width: 100%;
        }
      }

      &__name,
      &__data {
        padding: 2px 0;
      }
    }

    .previous-data {
      margin: 16px 0 76px;
      padding: 52px 24px 24px;
      border-radius: 8px;
      background-color: $disabled_bg;
      position: relative;

      &.hidden {
        margin-top: 0;
        height: 0;
        padding: 0;
        table,
        .created-at {
          display: none;
        }

        & > button svg {
          rotate: -90deg;
        }
      }

      .created-at {
        position: absolute;
        right: 24px;
        top: 24px;
        color: $darkgray;
      }

      .info-table__name {
        width: 116px;
      }

      & > button {
        position: absolute;
        bottom: -40px;
        left: -4px;

        svg {
          rotate: 90deg;
          path {
            stroke: $accent_70;
            fill: none !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    padding-top: 36px;

    &__header {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: flex-start;

      .actions {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        width: 100%;
        height: 32px;
        justify-content: space-between;
      }
    }

    &__body {
      transform: translateX(-44px);
    }

    .previous-data {
      padding: 52px 16px 16px;
      border-radius: 0;

      .created-at {
        left: 16px;
        right: unset;
        top: 20px;
      }

      .info-table__name {
        width: 100%;
      }
    }
  }
}