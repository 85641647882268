@import 'src/styles/colors.scss';

.checkbox-default {
  height: min-content;

  .ant-checkbox:after, .ant-checkbox-checked:after {
    content: none;
  }
  &:not(.ant-checkbox-wrapper-disabled) {
    .ant-checkbox-inner {
      border-color: $lightgray;
      &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: "";
        width: 24px;
        height: 24px;
        background-color: transparent;
        z-index: -1;
        border-radius: $border_radius;
        transition: 0.3s;
      }
    }

    .ant-checkbox-wrapper {
      &:focus .ant-checkbox-inner {
        box-shadow: 0 0 0 4px $accent_20;
      }
      &:hover .ant-checkbox-inner {
        box-shadow: 0 0 0 4px $border;
      }
      &:active .ant-checkbox-inner {
        box-shadow: 0 0 0 4px $accent_30;
      }
    }
    .ant-checkbox-wrapper-checked .ant-checkbox-inner {
      border-color: $accent_70 !important;
    }
  }

  .ant-form-item-control-input {
    min-height: 24px;
  }

  .ant-checkbox-wrapper > span:not(.ant-checkbox) {
    padding: 0 0 0 12px;
  }
  .typography {
    color: $main;
    min-height: 24px;
  }

  &.ant-checkbox-wrapper-disabled {
    .ant-checkbox-inner {
      border-color: $border !important;
      background-color: $white;
    }
    &.ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        border-color: $accent_30 !important;
        background-color: $accent_30;
      }
      .ant-checkbox-inner:after {
        border-color: $white;
      }
    }
    .typography {
      color: $disabled_text;
    }
  }

}