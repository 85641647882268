@import "src/styles/colors";
@import "src/styles/breakpoints";

.table-default-antd {
  colgroup {
    col {
      min-width: 80px;
      &.ant-table-expand-icon-col {
        min-width: 0;
        width: 0;
        max-width: 0;
      }
      &.ant-table-selection-col {
        width: 40px;
        min-width: 40px;
      }
    }
  }

  .table-default__error {
    padding: 8px 12px;
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #FF3C3C;
    font-weight: 400;
    background-color: #FFEBEB;
    border-bottom: 1px solid #FF3C3C;
  }

  .ant-table-empty {
    background: none;

    .ant-table-content {
      overflow: hidden !important;
    }
    .ant-table-container {
      &:after {
        display: none;
      }
    }
    .ant-table-cell {
      padding: 0;
      box-shadow: none;
    }
  }
  .ant-spin-spinning.ant-spin {
    max-height: 100%;
  }

  .ant-table-row {
    &:hover {
      .ant-table-cell {
       background-color: $disabled_bg;
      }
    }
    &-expand-icon-cell {
      padding: 0 !important;
      position: relative;
      z-index: 3;
    }
    &.ant-table-row-selected {
      background-color: $accent_20;

      &:hover .ant-table-cell {
        background-color: $accent_30;
      }

      .ant-table-cell {
        box-shadow: inset 0 -1px 0 $accent_70;
      }

      &:first-child .ant-table-cell {
        box-shadow: inset 0 -1px 0 $accent_70, inset 0 1px 0 $accent_70;
      }
    }
  }
  .ant-table-cell {
    height: 36px;
    padding: 10px 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border: 0;
    box-sizing: border-box;
    box-shadow: inset 0 -1px 0 $border;
    background-color: #fff;

    &.ant-table-column-sort {
      background-color: $white;
    }

    .ant-table-row-expand-icon {
      display: none;
    }

    .ant-table-cell-content {
      &__with-arrow {
        display: flex;
        align-items: center;

        svg {
          display: block;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          border-radius: $border_radius;
          cursor: pointer;
          transition: background-color .3s;
          transform: rotate(180deg);

          &:hover {
            background-color: $border;
          }
        }

        &.open {
          svg {
            transform: rotate(270deg);
          }
        }
      }

      &__without-arrow {
        padding-left: 24px;
      }
    }
  }

  .ant-table-expanded-row {
    & > .ant-table-cell {
      padding: 0;
      .ant-table {
        margin-left: 0 !important;
      }
      .table-default-antd {
        &:before, &:after {
          display: none;
        }
        .ant-table-thead {
          display: none;
        }
      }
    }

    &-level-1 .ant-table-content {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 6px 12px;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: $main;
      background-color: $disabled_bg;
      box-shadow: inset -1px -1px 0 $border;
      cursor: default;

      &.ant-table-selection-column {
        box-shadow: inset 0px -1px 0 $border;

        .ant-checkbox-input {
          width: 14px;
          height: 14px;
        }

        & + .ant-table-cell {
          padding-left: 0;
        }
      }

      &:last-of-type {
        box-shadow: inset 0 -1px 0px $border;
      }

      &:hover {
        background-color: $disabled_bg;
      }

      &:before {
        display: none;
      }

      .ant-table-column-sorters .ant-table-column-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          margin-left: 8px;
          cursor: pointer;
          flex: 0 0 24px;

          &.asc #Ascending,
          &.desc #Descending,
          &.ascend #Ascending,
          &.descend #Descending,
          &.ascending #Ascending,
          &.descending #Descending,
          &.ascending #Ascending,
          &.descending #Descending {
            fill: $main;
          }

          &:hover,
          &.open {
            background-color: $border;
            border-radius: 4px;
          }
        }
      }
      .ant-table-column-sorter-full {
        display: none;
      }
    }
  }

  .ant-table-empty table {
    table-layout: unset !important;
  }

  .ant-table-content {
    overflow: auto hidden;

    &::-webkit-scrollbar {
      background-color: $disabled_bg;
      height: 20px !important;
      border: 6px solid white !important;
      background-clip: padding-box;
      border-radius: 9999px !important;
    }
    &::-webkit-scrollbar-thumb {
      border: 6px solid transparent !important;
      background-clip: padding-box;
      border-radius: 9999px !important;
    }

    .ant-table-selection-column {

      .ant-checkbox-input {
        width: 14px;
        height: 14px;
      }

      & + .ant-table-cell {
        padding-left: 0;
      }
    }
  }

  .ant-spin-dot {
    width: 24px;
    height: 24px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: $screen-mobile-max) {
    border-radius: 0;

    colgroup col {
      min-width: 0;
    }

    &:has(>.table-mobile-default__content) .table-default-antd__content {
      display: none;
    }

    .table-mobile-default__content {
      display: block;
    }
  }
}

.sorting-list {
  position: fixed;
  transform: translateY(calc(50% + 22px));
  padding: 8px 0;

  &__option {
    padding: 8px 48px 8px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $main;
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: $disabled_bg;
    }

    .checkmark {
      position: absolute;
      top: 6px;
      right: 12px;
    }

  }

  &:not(.open) {
    display: none;
  }
}

.table-mobile-default {
  &__content {
    display: none;
    position: relative;

    .item {
      padding: 16px;
      border-bottom: 1px solid #E1E7F0;

      &__row {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .title {
          margin-right: 16px;
          width: 120px;
          flex: 0 0 120px;

          svg {
            display: none;
          }
        }

        .data {
          max-width: calc(100% - 136px);
          font-weight: 600;

          &.extra {
            margin-top: 16px;
            &:empty {
              display: none;
            }
            button {
              flex: 1 1 auto;
            }
          }
        }
      }
    }

    .loading-overflow {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba($white, 0.5);

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -10px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
